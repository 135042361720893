@import '../variables.scss';

.custom-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.custom-input-label-header {
  position: absolute;
  font-size: 10px;
  color: $BLACK;
  top: -5px;
  left: 15px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $WHITE;
}
