@import '../variables.scss';

.settings-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 55px);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 25px;
  overflow-y: auto;
  box-sizing: border-box;
}
