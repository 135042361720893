@import '../variables.scss';


.selector-list-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgb(244,244,244);
}