@import '../variables.scss';

// .switch {
//   position: relative;
//   display: block;
//   width: 100%;
//   height: 100%;
//   max-width: 90px;
//   max-height: 55px;
// }

// .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: #0000006b;
//   transition: 0.5s;
//   border: 3px solid $WHITE;
//   box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1) inset;
// }

// .switch input {
//     display: none;
// }

// .slider::before {
//     position: absolute;
//     content: "";
//     cursor: pointer;
//     top: 3px;
//     bottom: 14px;
//     background-color: lightgray;
//     transition: 0.5s;
// }

// input:checked + .slider{
//     background-color: $ORANGE_RED;
// }

// input:checked + .slider::before{
//     transform: translateX(calc(140%)) rotateZ(45deg);
//     background: $WHITE;
// }

// .slider.round{
//     border-radius: 35px;
// }

// .slider.round::before {
//     width: 43%;
//     height: 53%;
//     border-top-left-radius: 50px;
//     border-top-right-radius: 50px;
//     transform: rotateZ(-45deg);
//     background: $WHITE;
// }

.center {
  width: 50px;
  height: 25px;
  // background-color: green;

  input[type='checkbox'] {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-appearance: none;
    transition: 0.5s;
    background: #757575;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  }

  input:checked[type='checkbox'] {
    background-color: $ORANGE_RED;
  }

  input[type='checkbox']::before {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    transform: scale(1.1, 1.1);
    background-color: $WHITE;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }

  input:checked[type='checkbox']::before {
    left: 50%;
  }
}
