@import './app/variables.scss';

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // position: relative;
  background-color: $BLACK;
  overflow: hidden;
  height: 100%;
  width: 100%;
  min-width: 300px;
}

p {
  margin: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ---------------Global styles------------------*/

input {
  border: none;
  &:focus-visible {
    outline: none;
  }
}
.custom-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.custom-button-disabled {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 20%);
}

.custom-button-enabled {
  background-color: $ORANGE_RED;
}

.sso-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fbfbfb;
  border-width: 1px;
  border-color: #e1e1e1;
  width: 40%;
  border-radius: 15px;
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.custom-input {
  background-color: transparent;
  width: 100%;
  font-size: 11px;
  border: 0;

  &:focus-visible {
    outline: none;
  }
}

.active-text {
  color: $BLACK;
  &:hover {
    cursor: pointer;
    color: $ORANGE_RED;
  }
}

.form-input {
  min-height: 25px;
  color: BLACK;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid $INPUT_BORDER;
  font-size: 12px;
}

.selector-radio-btn {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 3px;
  border: 1px solid $INPUT_BORDER;
}

.active-btn {
  transition: all 0.3s ease-out;
  &:hover {
    cursor: pointer;
    background-color: $ORANGE_RED;

    span {
      color: $WHITE;
    }
  }
}

.on-press-cls{
  transition: all 0.3s ease-out;
  &:hover {
    background-color: $INPUT_BORDER;
  }
}

.list-item-cls {
  width: calc(100% - 55px);
}

.on-user-avatar {
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  &:hover {
    border: 1px solid $ORANGE_RED;
  }
}