@import '../variables.scss';

.screen-holder-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $BLACK;
}

.app-screen-holder-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $BLACK;
}

.screen-holder-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
}

.screen-holder-wrapper {
  width: 100%;
  height: calc(100% - 60px);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  background: $WHITE;
}

.screen-holder-small-header {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // z-index: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  
  // height: 33%;
  background-color: $BLACK;

  .center-logo {
    width: 70%;
    max-width: 300px;
  }
}

.screen-holder-small-drawer {
  // position: absolute;
  // bottom: 0;
  flex: 2;
  width: 100%;
  // top: HALF_SCREEN_HEADER_HEIGHT;
  background-color: $WHITE;
  // height: 66%;

  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  // padding-top: 28px;
  overflow: auto;
  box-sizing: border-box;
}

.full-screen-wrapper{
  height: calc(100% - 60px);
}

// @media only screen and (min-width: 768px) {
//   .screen-holder-container {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     height: 100%;
//     background: $BLACK;
//     padding: 1em;
//     box-sizing: border-box;
//   }

//   .screen-holder-small-header {
//     position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//     width: 60%;
//     background-color: $BLACK;
//     top: unset;
//     left: unset;
//     right: unset;
//     z-index: unset;

//     .center-logo {
//       width: 70%;
//       max-width: 300px;
//     }
//   }

//   .screen-holder-small-drawer {
//     position: relative;
//     bottom: unset;
//     height: 100%;
//     // top: HALF_SCREEN_HEADER_HEIGHT;
//     background-color: $WHITE;
//     width: 40%;
//     min-width: 370px;
//     border-top-left-radius: unset;
//     border-top-right-radius: unset;
//     border-radius: 30px;
//     padding-top: 28px;
//     overflow: auto;
//   }
// }

.search-width {
  width: calc(100% - 145px);
}
.on-hover-icon {

  transition: all 0.3s ease-out;
  &:hover {
    background-color: rgba(244,244,244,0.2);
  }
}