@import '../variables.scss';

.custom-checkbox {
    width: 20px;
    height: 20px;    
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 5px;
    position: relative;

    input[type="checkbox"] {
        visibility: hidden;
        display: none;
    }
}

.custom-checkbox label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    border-radius: 4px;

    border: 1px solid rgba(0,0,0,0.3);
    background: #fcfff4;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfff4', endColorstr='#b3bead',GradientType=0 );
}

.custom-checkbox label:hover {
    border: 1px solid $ORANGE_RED !important;
}

.custom-checkbox label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 4px;
    left: 4px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;
    

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}


.custom-checkbox label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.5;
}

.custom-checkbox input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;

    
}

.custom-checkbox input[type=checkbox]:checked + label {
    border: 1px solid $ORANGE_RED;
}