$BLACK: #000000;
$WHITE: #ffffff;
$ORANGE_RED: #ff6400;
$INPUT_BORDER: rgb(234, 234, 234);
$PLACEHOLDER: #a4a4a4;
$CANCEL: #888888;
$INPUT_BACKGROUND: #efefef;
$DEFAULT_BORDER: #979797;
$RED: red;
$TRANSPARENT: transparent;
$FULL_SCREEN_HEADER_HEIGHT: 60px;
