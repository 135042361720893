@import '../variables.scss';

.login-container {
  display: flex;
  flex-direction: column;
  background-color: $WHITE;
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
