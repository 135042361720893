
@import '../variables.scss';

.form-selector-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .input-container {
        position: relative;
        width: 100%;
        border-radius: 10px;
        border: 1px solid $INPUT_BORDER;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        box-Sizing: border-box;
    }
}