@import '../variables.scss';

.nav-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .app-content{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 50px);
    width: 100%;
    box-sizing: border-box;
  }
  .bottom-nav {
    width: 100%;
    height: 50px;
  }

  //Home tab styles
  .top-tab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom-width: 1px;
    border-bottom-color: $INPUT_BORDER;
    border-bottom-style: solid;
    max-height: 50px;
  }

  .top-tab-content-container {
    width: 100%; 
    height: calc(100% - 50px); 
    overflow: hidden;
  }
}
